import React from 'react'
import Layout from '../../components/layout'

import info from '../../images/info/produktinfo.jpg'


const InfoPage = () => (
  <Layout>
    <section className="section-wrapper big">
      <header className="section-header">
        <h3>Produktinfo</h3>
      </header>

      <div className="row">
        <div className="col-md-8">
          <div style={{ paddingBottom: '20px' }}>
            <h5 className="underlined-title">Erfahren Sie mehr über ZEP</h5>
            <p>
              Für detailllierte Infos zu ZEP klicken Sie sich durch die 4 obenstehenden Themenbereiche
              „Der Prozess“ „Die Applikation“ „Die Technik“ „Der HR-Kurzprozess“.
            </p>
            <p>Oder kontaktieren Sie uns ganz unverbindlich.</p>
          </div>
          <div>
            <h5 className="underlined-title">Das Wichtigste in Kürze</h5>
            <b>ZEP</b> generiert die Zeugnisarten Schlusszeugnis, Zwischenzeugnis und Arbeitsbestätigung.<br />
            <b>ZEP</b> stellt mehr als <b>1’800 Textbausteine</b> (pro Sprache) zur Verfügung.<br />
            <b>ZEP</b> steht in <b>Deutsch, Französisch, Italienisch</b> und <b>Englisch</b> zur Verfügung.<br />
            <b>ZEP</b> arbeitet mit <b>Kompetenzbewertungen.</b><br />
            <b>ZEP</b> bietet pro Kompetenzbewertung mehrere Textvarianten an.<br />
            <b>ZEP</b> arbeitet mit Aufgabenlisten für Mitarbeitende, Vorgesetze und Personalabteilung.<br />
            <b>ZEP</b> ist integrierbar in bestehende <b>HR Portale</b> (Xpert.HRM, SAP usw.).<br />
            <b>ZEP</b> generiert Zeugnisdokumente in verschiedenen Formaten (z.B. DOC, RTF)<br />
            <b>ZEP</b> liefert <b>Auswertungen</b> über <b>Durchlaufszeiten</b> und <b>Termintreue.</b><br />
          </div>
        </div>
        <div className="col-md-4" style={{ width: '100%', margin: '15px auto', textAlign: 'center' }}>
          <img src={info} style={{ maxWidth: '100%' }} alt="Produktinfo" />
        </div>
      </div>
    </section>
  </Layout>
)

export default InfoPage
